<template>
  <SubPageWrapper empty-cols>
    <template #loader>
      <SlOverlay :show="isProcessing" />
    </template>

    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="tabs"
      >
        <template #header>
          <span>
            {{ $t('Web.DbImport.LabelSettings') }}
          </span>
          <SlInfoButton
            v-tooltip="getTooltip($t('Web.DbImport.TooltipToImport'))"
          />
        </template>
      </SlTabList>
    </template>
    <ConnectorContentWrapper
      :title="pageTitle"
      :is-valid-title="isValidTitle"
    >
      <template #actions>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          v-if="!isNew"
          variant="secondary"
          @click="handleSave"
        >
          {{ $t('Main.Ui.acSave') }}
        </SlButton>
        <SlButton @click="handleImport">
          {{ importLabel }}
        </SlButton>
      </template>
      <template>
        <SlTabContent
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <ValidationObserver
            :ref="`${tab.value}-observer`"
            tag="form"
          >
            <ConnectorContentBlock>
              <template #title>
                {{ subTabTitle }}
              </template>
              <component
                :is="currentTabComponent"
                :is-new="isNew"
              />
            </ConnectorContentBlock>
          </ValidationObserver>
        </SlTabContent>
      </template>
    </ConnectorContentWrapper>
    <template
      v-if="connectorData.modal"
      #right-col
    >
      <div class="connection-info">
        <div class="connection-info__title body-4-md grey-60">
          {{ reconnectTitle }}
        </div>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="openReconnectModal"
        >
          {{ reconnectButton }}
        </SlButton>
      </div>
    </template>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentWrapper from '@/components/Connections/Common/ConnectorContentWrapper';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { modal } from '@/mixins/modal';
import { connection } from '@/mixins/connection';
import { metaInfo } from '@/mixins/metaInfo';
import modalsId from '@/config/shared/modalsId.config';
import { connectorByType, connectionTypes } from '@/config/connection';
import { updateFgsFlags, operationInfoTypes } from '@/config/api/operations.config';
import { routeNames } from '@/config/router/router.config';
import { HISTORICAL_TRANSACTIONS_TEMPLATE_URL } from '@/config/shared/resources.config';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { preventTabClose, openLink } from '@/helpers/shared/webAPI';
import { getTooltip } from '@/helpers/shared/tooltip';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'BaseConnector',
  components: {
    ConnectorContentBlock,
    ConnectorContentWrapper
  },
  mixins: [modal, connection, metaInfo],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      modalsId,
      tabModel: null,
      isProcessing: false,
      tabsDisabled: false,
      isValidTitle: true,
      isRedirect: false,
      tabsValidationState: {
        required: false,
        optional: true
      },
      tabs: [
        {
          label: this.$t('Web.BaseConnectors.Tabs.Connection'),
          subtitle: this.$t('Web.BaseConnectors.Tabs.Subtitle.Connection'),
          value: tabKeys.REQUIRED,
          required: true
        },
        {
          label: this.$t('Web.BaseConnectors.Tabs.Advanced'),
          subtitle: this.$t('Web.BaseConnectors.Tabs.Subtitle.Advanced'),
          value: tabKeys.OPTIONAL
        }
      ],
      getTooltip
    };
  },
  provide() {
    return {
      getTooltip: this.getTooltip,
      historicalTransactionsTemplate: HISTORICAL_TRANSACTIONS_TEMPLATE_URL
    };
  },
  computed: {
    ...mapState({
      wasEdited: (state) => state.connection.wasEdited,
      connectionDataByType: (state) => state.connection.connectionData,
      currentConnector: (state) => state.connection.selectedConnector
    }),
    ...mapGetters({
      projectLabel: 'project/projectLabel'
    }),
    connectorByType() {
      return connectorByType(this);
    },
    connectorData() {
      return this.connectorByType[this.currentConnector];
    },
    tabsToValidate() {
      return this.connectorData.validate || [tabKeys.REQUIRED];
    },
    connectionData() {
      return this.connectionDataByType[this.currentConnector];
    },
    spireDatabases() {
      return this.connectionData.required.databases;
    },
    sourceConnectData() {
      return this.sourceConnectDataByType[this.currentConnector];
    },
    currentTabComponent() {
      return this.connectorData.components[this.tabModel];
    },
    pageTitle() {
      if (!this.isNew) {
        return this.$t('Web.BaseConnectors.TitleEdit', {
          1: this.projectLabel
        });
      }

      return this.connectionData?.commonData?.slProjectName || '';
    },
    subTabTitle() {
      const currentTabConfig = this.tabs.find((tab) => tab.value === this.tabModel);

      return currentTabConfig.subtitle;
    },
    importLabel() {
      return this.isNew ? this.$t('Import.Dialog.Import') : this.$t('Import.Dialog.Reimport');
    },
    reconnectInfo() {
      return this.connectorData.reconnect(this, this.sourceConnectData);
    },
    reconnectTitle() {
      return this.reconnectInfo.title || this.$t('Web.BaseConnectors.LabelConnection');
    },
    reconnectButton() {
      return this.reconnectInfo.button || this.$t('Web.BaseConnectors.BtnReconnect');
    }
  },
  watch: {
    async tabModel(current, old) {
      if (!old) {
        return;
      }

      const observer = this.$refs[`${old}-observer`][0];

      if (!observer || !this.tabsToValidate.includes(old)) {
        this.isValid = true;

        return;
      }

      this.tabsValidationState[old] = await observer.validate();
    },
    wasEdited(value) {
      preventTabClose(value);
    }
  },
  async beforeMount() {
    this.selectConnector(this.$route.params.connector);

    if (!this.isNew) {
      await this.getConnectionInfo();
    }

    if (this.currentConnector === connectionTypes.SPIRE && !this.spireDatabases) {
      const connected = await this.getSpireDatabases();

      if (!connected) {
        this.showModal(modalsId.SPIRE_CONNECTOR);
      }

      return;
    }
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
    this.resetEditingState();
  },
  methods: {
    ...mapActions({
      reimportConnectionInfo: 'connection/reimportConnectionInfo',
      importConnectionInfo: 'connection/importConnectionInfo',
      saveConnectionInfo: 'connection/saveConnectionInfo',
      getSpireDatabases: 'spire/getSpireDatabases',
      checkQbConnection: 'qb/checkQbConnection',
      resetState: 'connection/resetState',
      resetEditingState: 'connection/resetEditingState',
      selectConnector: 'connection/selectConnector',
      getConnectionInfo: 'connection/getConnectionInfo',
      subscribe: 'operations/subscribe',
      logout: 'user/logout'
    }),
    // calls from wrapper
    beforeRouteLeaveGuard(to, from, next) {
      if (this.isRedirect || !this.wasEdited) {
        return next();
      }

      if (this.wasEdited) {
        return this.showExitConfirm(next);
      }

      next();
    },
    showExitConfirm(next) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_save_double',
        title: this.$t('Web.Modals.UnsavedConfirm.TitleLeaveProject'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextLeaveProject'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonLeave'),
        confirmCallback: () => {
          this.isRedirect = true;
          this.isEdited = false;

          next();
        }
      });
    },
    openReconnectModal() {
      setTimeout(() => {
        this.showModal(this.connectorData.modal, { isReconnect: true });
      });
    },
    handleCancel() {
      if (!this.wasEdited) {
        return this.goBack();
      }

      this.showExitConfirm(this.goBack);
    },
    async validateTab() {
      if (!this.tabsToValidate.includes(this.tabModel)) {
        return true;
      }

      const observer = this.$refs[`${this.tabModel}-observer`]?.[0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    },
    async handleImport() {
      const isValid = await this.validateTab();

      this.isValidTitle = !!this.pageTitle;
      this.tabsValidationState[this.tabModel] = isValid;

      const secondTabValidationState = this.tabModel === tabKeys.REQUIRED
        ? this.tabsValidationState.optional
        : this.tabsValidationState.required;

      if (!isValid || !secondTabValidationState || !this.isValidTitle) {
        const invalidTab = Object.keys(this.tabsValidationState)
          .find(key => !this.tabsValidationState[key]);

        this.tabModel = invalidTab || tabKeys.REQUIRED;

        this.$nextTick(() => {
          this.validateTab();
        });

        return;
      }

      const action = this.isNew ? this.importConnectionInfo : this.reimportConnectionInfo;

      try {
        this.isProcessing = true;

        const { subscriberData } = await this.subscribe({
          subscriber: () => action(),
          ignoreLastChanges: this.isNew,
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          },
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId);
            }
          }
        });

        this.isRedirect = true;

        if (!this.$sl_isEditingRoute) {
          ampli.projectCreated();

          return this.$store.dispatch('manageProjects/openProject', {
            ...subscriberData
          });
        } else {
          this.$notify({
            type: 'success',
            title: this.$t('Web.UpdateData.Success')
          });
        }

        this.$router.push({
          name: routeNames.DEMAND
        });
      } catch (e) {
        const errorMessage = e?.message?.error?._fallback
          || e?.message
          || e?.description;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            title: this.$t('Web.DbImport.Errors.NotifyTitleImport'),
            text: errorMessage,
            duration: -1
          });
        }

        this.logout({
          e,
          from: 'BaseConnectorHandleImport'
        });
      } finally {
        this.isProcessing = false;
        this.resetEditingState();
      }
    },
    async handleSave() {
      try {
        this.isProcessing = true;

        await this.saveConnectionInfo({ type: this.currentConnector });
        this.resetEditingState();

        this.$notify({
          type: 'success',
          title: this.$t('Web.SaveProject.Success')
        });
      } catch (e) {
        this.logout({ e, from: 'BaseConnectorHandleSave' });
      } finally {
        this.isProcessing = false;

        this.$router.push({
          name: routeNames.DEMAND
        });
      }
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/database/index.scss";

::v-deep {
  .connector-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &--simple {
      .connector-content-block__row {
        max-width: 512px;
      }
    }
  }

  .sl-tab-content {
    overflow: visible;
  }
}
</style>
